// import { createMuiTheme } from '@mui/material/styles'
// Workaround for https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
import { unstable_createMuiStrictModeTheme as createMuiTheme, adaptV4Theme } from '@mui/material';

import { Definitions } from './Typography';
import Hues, { DefaultBodyHue, DefaultIconHue } from './Hues';

const {
  h1, h2, h3, h4, h5, body1, body2, overline1, subtitle1, subtitle2,
} = Definitions;

export const BoxShadows = {
  appBar: {
    top: '0px 0px 16px 0px rgba(39, 53, 70, 0.08)',
    bottom: '0px 0px 24px 0px rgba(39, 53, 70, 0.08)',
  },
};

const theme = createMuiTheme(
  adaptV4Theme({
    props: {
      MuiSvgIcon: {
        htmlColor: DefaultIconHue,
      },
    },
    spacing: 8,
    typography: {
      ...{
        fontFamily: 'museo-sans, sans-serif',
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
      h1,
      h2,
      h3,
      h4,
      h5,
      body1: {
        ...body1,
        letterSpacing: 0,
      },
      body2: {
        ...body2,
        letterSpacing: 0,
      },
      overline: overline1,
      subtitle1,
      subtitle2,
    },
    palette: {
      primary: {
        light: Hues.primary._70,
        main: Hues.primary._100,
        dark: Hues.primary._110,
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: Hues.secondary._100,
      },
      text: {
        primary: DefaultBodyHue,
      },
      error: {
        main: Hues.octonary._100,
        light: Hues.octonary._70,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  }),
);

theme.typography.body1 = {
  ...theme.typography.body1,
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
};

export default theme;
