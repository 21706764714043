export const DataRegionTypes = {
  SE: 'SE',
  UK: 'UK',
  US: 'US',
} as const;

export type DataRegion = typeof DataRegionTypes[keyof typeof DataRegionTypes];
export type DataRegionMap = keyof typeof DataRegionTypes;

export const isTypeDataRegion = (value: unknown): value is DataRegion => (
  Object.values(DataRegionTypes).includes(value as DataRegion)
);

export const isTypeDataRegionMap = (value: unknown): value is DataRegionMap => (
  Object.keys(DataRegionTypes).includes(value as DataRegionMap)
);
