import { isSSR } from '@utils/ssr/ssr';

export type TUtmParams = Record<string, string>;

export function getUtmParams(): TUtmParams | undefined {
  const utmParams = localStorage.getItem('UTM_PARAMS');

  return utmParams ? JSON.parse(utmParams) : undefined;
}

export function storeUtmParams(queryString?: string | null) {
  if (isSSR() || !queryString) {
    return;
  }

  const utmParams: TUtmParams = {};
  Object.entries(Object.fromEntries(new URLSearchParams(queryString).entries())).forEach(
    ([key, value]) => {
      if (key.substring(0, 4) === 'utm_') {
        utmParams[key] = value;
      }
    },
  );

  localStorage.setItem('UTM_PARAMS', JSON.stringify(utmParams));
}

export function removeUtmParams() {
  localStorage.removeItem('UTM_PARAMS');
}
