import React, { useState } from 'react';
import type { ReactNode } from 'react';

import { Patient } from '@/src/externalApi/models/Patient';
import getUserData from '@/src/externalApi/hooks/getUserData';

import ProfileContext from './ProfileContext';

const useProfileContextProvider = () => {
  const [profile, setProfile] = useState<Patient | undefined>();

  const refreshProfile = async () => {
    const userResponse = await getUserData();

    if (!userResponse) {
      throw new Error();
    }

    const updatedUser = userResponse?.data?.data;
    setProfile(updatedUser);

    return updatedUser;
  };

  return {
    profile,
    setProfile,
    refreshProfile,
  };
};

const ProfileContextProvider = ({ children }: { children: ReactNode }) => {
  const value = useProfileContextProvider();
  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export type ProfileContextValue = ReturnType<typeof useProfileContextProvider>;

export default ProfileContextProvider;
