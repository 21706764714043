/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import {
  Box,
  GlobalStyles,
} from '@mui/material';
import { Definitions } from '@/src/theme/Typography';
import Hues from '@/src/theme/Hues';

const {
  h1, h2, h3, h4, h5, body1,
} = Definitions;

const inputGlobalStyles = (
  <GlobalStyles styles={theme => ({
    html: {
      boxSizing: 'border-box',
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
    'body, html, #root': {
      height: '100%',
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      fontFamily: 'museo-sans, sans-serif',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      overflowX: 'hidden', // to avoid scrollbars by page transitions
    },
    'a, a:visited, a:hover, a:active': {
      color: Hues.primary._100,
      textDecoration: 'none',
    },
    h1,
    h2,
    h3: {
      ...h3,
      margin: `0 0 ${theme.spacing(2)} 0`,
    },
    h4,
    h5,
    p: {
      ...body1,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      lineHeight: 1.5,
      letterSpacing: 0,
    },
    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
    /* display: none; <- Crashes Chrome on hover */
      WebkitAppearance: 'none',
      margin: 0 /* <-- Apparently some margin are still there even though it's hidden */,
    },
    'input[type=number]': {
      MozAppearance: 'textfield' /* Firefox */,
    },
    ul: {
      paddingInlineStart: '20px',
    },
  })}
  />
);

type TProps = {
  children: ReactNode,
};

const RootLayout = ({ children }: TProps) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
    {inputGlobalStyles}
    {children}
  </Box>
);

export default RootLayout;
