/* eslint-disable max-len */
export default {
  common: {
    more_information: 'Mer information',
    no_services_found: 'Inga tjänster hittades.',
    no_health_care_professionals_found: 'Ingen personal hittades.',
    patient_fee: 'Patientavgift',
    service: 'Tjänst',
    staff: 'Personal',
    type_of_visit: 'Typ av besök',
    when: 'När',
    yes: 'ja',
    no: 'nej',
    yes_answer: 'Ja',
    no_answer: 'Nej',
    next: 'Nästa',
    no_patient_fee: 'Ingen patientavgift',
    exemption_card: 'Frikort',
    exemption_card_supported: 'frikort gäller',
    general: 'Allmänt',
    licensed_physiotherapist: 'Leg. fysioterapeut',
  },
  form: {
    phone_placeholder: 'Mobilnummer',
    phone_label: 'Ange telefonnummer',
    email_placeholder: 'E-postadress',
    email_label: 'Ange e-postadress',
    pin_placeholder: 'Ditt personnummer',
    pin_label: 'Ange personnummer (12 siffror)',
    password_placeholder: 'Ditt lösenord',
    card_number: 'Kortnummer',
    expiry_date: 'Utgångsdatum',
    card_cvc: 'Säkerhetskod',
    exemption_card_placeholder: 'Frikortsnummer',
    add_exemption_card: 'Lägg till frikort',
    no_exemption_card: 'Inget kort har lagts till',
    times: 'gånger',
  },
  bankid: {
    bankid: 'BankID',
    book_a_time: 'Boka videosamtal med en Fysioterapeut',
    book_times: 'Logga in med BankID för att boka tid.',
    create_title: 'Vänligen bekräfta din identitet med BankID',
    error: {
      already_in_progress: 'Det finns en pågående session.',
      cancelled: 'Åtgärden avbruten. Försök igen.',
      certificate_err:
        'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din bank.',
      client_err: 'Internt tekniskt fel. Uppdatera BankID-programmet och försök igen.',
      expired_transaction:
        'BankID-appen svarar inte. Kontrollera att det är startat och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen.',
      internal_error: 'Internt tekniskt fel. Försök igen om en stund. Om problemet kvarstår, kontakta vår support.',
      retry: 'Internt tekniskt fel. Försök igen.',
      user_cancel: 'Åtgärden avbruten.',
      user_denied: 'Ditt konto har stängts av.',
      start_failed:
        'BankID-programmet verkar inte finnas i din dator eller telefon. Installera det och hämta ett BankID hos din bank. Installera programmet från install.bankid.com.',
    },
    info: {
      body: 'Du legitimerar dig med BankID.',
      body_new: 'Skriv in personnummer och starta BankID på din andra enhet',
      title: 'BankID på annan enhet',
    },
    invalid_soc_sec_number: 'Felaktigt personnummer',
    mobile_bankid: 'Mobilt BankID',
    pattern: 'ÅÅÅÅMMDDNNNN',
    personal_identity_number: 'Personnummer (12 siffror)',
    processing: 'Loggar in',
    remember_me: 'Kom ihåg mitt personnummer',
    start: 'Starta BankID',
    start_app_other: 'BankID på en annan enhet',
    start_app_current: 'BankID på den här enheten',
    start_app_other_info: 'Starta BankID och tryck på<br />“Skanna QR-kod”',
    start_new: 'Logga in med BankID',
    open: 'Öppna BankID',
    app_other: 'Har du BankID på en annan enhet?',
    app_current: 'Har du BankID på den här enheten?',
    login_cta: 'Klicka här för att logga in',
    status: {
      no_client: 'Starta BankID-programmet på din telefon, surfplatta eller dator..',
      outstanding_transaction: 'Försöker starta BankID-appen.',
      outstanding_transaction_new:
        'BankID-appen kommer att starta automatiskt om den är installerad på den här enheten.',
      started: 'Söker efter BankID. Det kan ta en liten stund...',
      started_secondary:
        'Om det har gått några sekunder och inte BankID har hittats så har du sannolikt inget BankID i den här enheten som går att använda för den aktuella inloggningen. Om du inte har något BankID kan du hämta ett hos din internetbank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.',
      unknown_user: 'För att kunna logga in på Joint Academy behöver du först skapa ett konto.',
      user_sign: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera.',
    },
    subtitle: 'Vi använder BankID för din säkerhet',
    success: 'Identifiering lyckades!',
    terms_bankid_link: 'allmänna villkor',
    terms_login_bankid: 'Genom att logga in godkänner du våra',
    terms_signup_bankid: 'Genom att skapa konto godkänner du våra',
    we_help_you: 'Vi hjälper dig med artros och ledsmärta.',
    why_bankid: 'Vi använder BankID för att skydda dina uppgifter.',
  },
  bankid_disturbance_popup: {
    body: 'Tyvärr går det inte att logga in just nu. Vi undersöker problemet och försöker lösa det så snart det går! Var god och försök igen om ett litet tag.',
    header: 'Försök igen senare',
  },
  login: {
    bankid: {
      processing: {
        text: 'BankID-appen kommer att starta automatiskt om den är installerad på den här enheten.',
        title: 'Loggar in',
      },
    },
    info: {
      bank_id: {
        text: 'Skriv in personnummer och starta BankID på din andra enhet',
        title: 'BankID på annan enhet',
      },
    },
  },
  errors: {
    password_length: 'Password must contain at least 8 characters',
    account_closed: 'Avslutat konto',
    account_discharged: 'Avslutad patient',
    account_in_selfcare: 'Egenvårdspatient',
    account_treatment_ended: 'Avslutad behandling',
    activity_not_found: 'Inga aktiviteter hittades.',
    all_empty_goal_fields: 'Fyll in ett fält för att skapa mål',
    any: {
      only: 'Obligatoriskt fält',
    },
    bankid_expired_transaction: {
      text: 'BankID-appen svarar inte. Kontrollera att den är startat och att du har internetanslutning. Om du inte har något giltigt BankID kan du hämta ett hos din bank. Försök sedan igen.',
    },
    email_taken: 'E-postadressen används redan!',
    error_action: 'Misslyckades med att hämta antal!',
    failed_cookie: 'Något gick fel. Försök igen.',
    field: {
      required: 'Obligatoriskt fält',
    },
    generic: 'Något gick fel. Försök igen.',
    generic_login: 'Något gick fel. Försök igen senare.',
    generic_try_again: 'Något gick fel. Kontrollera dina uppgifter.',
    icd_autocomplete_notfound: 'Ingen diagnoskod hittades',
    invalid_date: 'Ogiltigt datum',
    invalid_login: 'Fel e-postadress eller lösenord.',
    invalid_pin: 'Felaktigt personnummer',
    invite_already_exists_email: 'En inbjudan via e-post finns redan för denna patient',
    invite_already_exists_sms: 'En inbjudan via sms finns redan för denna patient',
    loading_failed: 'Laddningen misslyckades. Vänligen försök igen.',
    password: {
      any: {
        only: 'Lösenorden måste stämma överens.',
      },
      string: {
        max: 'Lösenordet får vara högst { maxLength } tecken.',
        min: 'Lösenordet måste vara minst { minLength } tecken.',
      },
    },
    patient_login: 'Denna version av appen är endast avsedd för fysioterapeuter. ',
    required: 'Obligatoriskt',
    session_expired: 'Du har blivit utloggad på grund av inaktivitet. Logga in igen.',
    contact_support: 'Något gick fel, kontakta vår support.',
    string: {
      email: 'Felaktigt e-postformat',
      empty: 'Obligatoriskt fält',
    },
    times_range: 'Måste vara mellan 1 och 999',
    times_required: 'Ange antal gånger för att fortsätta',
    too_few_characters: 'För få tecken',
    too_many_characters: 'För många tecken',
  },
  statuspage: {
    bankid: {
      login: {
        blocked: 'BankID har tekniska problem just nu. Vi jobbar på att lösa det. Vänligen prova igen senare.',
        warning: 'BankID har tekniska problem just nu så det kan ta längre tid en vanligt att logga in.',
      },
    },
    link: 'här',
    more_info: 'Läs mer',
    upcoming_maintenance:
      'Våra tjänster kommer vara otillgängliga för underhåll {startDate} från {startTime} till {endTime}.',
  },
  buttons: {
    add: 'Lägg till',
    add_new_goal: 'Lägg till nytt',
    back: 'Tillbaka',
    call: 'Ring',
    call_again: 'Försök igen',
    cancel: 'Avbryt',
    cancel_booking: 'Avboka',
    cancel_booking_yes: 'Ja, avboka',
    change: 'Ändra',
    change_language: 'Byt språk till engelska',
    choose: 'Välj',
    close: 'Stäng',
    confirm: 'Bekräfta',
    confirm_sign: 'Ja, signera',
    contact_us: 'Kontakta oss',
    continue: 'Fortsätt',
    continue_to_payment: 'Till betalning',
    create_password: 'Skapa nytt lösenord',
    delete: 'Ta bort',
    delete_goal: 'Radera mål',
    dismiss: 'Avfärda',
    done: 'Klar',
    edit: 'Ändra',
    edit_goal: 'Ändra mål',
    exit: 'Stäng',
    hide: 'Dölj',
    invite: 'Bjud in',
    load_more: 'Hämta fler',
    login_with_bankid: 'Logga in med BankID',
    next: 'Nästa',
    no: 'Nej',
    ok: 'OK',
    open_app: 'Öppna appen',
    pay: 'Betala',
    phone_call: 'Telefonsamtal',
    read_more: 'Läs mer',
    reply: 'Svara',
    reschedule: 'Omboka',
    reset_password: 'Återställ lösenord',
    reset_password_success: 'Till inloggningsvyn',
    retry: 'Försök igen',
    save: 'Spara',
    send: 'Skicka',
    send_invitation: 'Skicka inbjudan',
    show: 'Visa',
    sign: 'Signera',
    sign_in: 'Logga in',
    sign_in_with_google: 'Logga in med Google',
    submit: 'Skicka',
    suggestion_dismiss: 'Inte nu',
    unsure_do_not_know: 'Osäker / Vet ej',
    update: 'Uppdatera',
    video_call: 'Videosamtal',
    voice_call: 'Röstsamtal',
    write: 'Skriv',
    yes: 'Ja',
    yes_sign: 'Ja, signera',
  },
  view: {
    login: {
      title: 'Boka tid',
    },
    existing_patient: {
      title: 'Välkommen {name}',
      text: 'Du har redan ett konto med oss, vänligen logga in i Joint Academy-appen eller kontakta support.',
    },
    appointment_mode: {
      title: 'Välkommen {name}, hur vill du ses?',
      digital_title: 'Digitalt besök',
      digital_text: 'Vård och rådgivning via videosamtal i appen. Smidigt och enkelt.',
      digital_more_info:
        'Digitalt besök kan ske antingen via video eller telefon beroende på vilken tjänst du väljer. Mer information i nästa steg.',
      physical_title: 'Fysiskt besök',
      physical_text: 'Välkommen till vår klinik.',
      physical_more_info: 'Ett fysisk besök innebär att du träffar en fysioterapeut inne på vår klinik.',
      gdpr_consent: 'Jag godkänner de <0>allmänna villkoren</0> och <1>personuppgiftspolicyn.</1>',
      npo_consent:
        'Jag godkänner att vårdpersonal kan se min patientjournal via <0>Nationell patientöversikt (NPÖ)</0> eller tidigare vårdgivare vid behov.',
    },
    ask_exemption_card: {
      title: 'Har du frikort?',
    },
    profession: {
      title: 'Vem vill du träffa?',
    },
    service_category: {
      title: 'Hur kan vi hjälpa dig?',
    },
    health_care_professional: {
      title: 'Vem har behandlat dig tidigare?',
      skip: 'Fortsätt utan att välja',
    },
    purpose: {
      title: 'Är du här för ett nybesök eller återbesök?',
      text: 'Välj återbesök om du redan är patient på kliniken.',
      kick_off: 'Nybesök',
      follow_up: 'Återbesök',
    },
    service: {
      title: 'Välj tjänst',
      no_fee: 'Ingen avgift för dig som är över 85 år eller under 20 år',
    },
    health_questionnaire: {
      title: 'Hälsofrågor',
      emergency: 'Vid nödsituation - ring 112',
    },
    schedule: {
      title: 'Välj tid',
      pick_personel: 'Välj personal',
      all: 'Alla',
      today: 'Idag',
      week: 'Vecka',
      previous_week: 'Föregående vecka',
      next_week: 'Nästa vecka',
      pick_date: 'Välj datum',
      pick_new_time: 'Välj ny tid',
      no_available_time: 'Inga tider',
      time_not_available: 'Tyvärr är din valda tid inte längre tillgänglig',
      video_call: 'Videosamtal',
      x_minutes: '{minutes} minuter',
    },
    reschedule: {
      title: 'Boka en ny tid',
      cancel_are_you_sure: 'Är du säker på att du vill avboka denna bokningen?',
      cancel_success: 'Din bokning är nu avbokad!',
      no_appointmnet: 'Din bokning hittades inte.',
      reschedule_success: 'Din bokning är nu ombokad!',
    },
    summary: {
      title: 'Summering',
      about_patient_fees: 'Läs mer om patientavgifter',
      info: 'Du får en faktura på e-post efter mötet. Avboka senast 24 timmar innan om du inte kan komma. En avgift utgår om du inte kommer på din bokade tid.<br/><br/>Möte via vår klinik {clinic} i {region}.',
      patient_fee_drawer_title: 'Om patientavgift',
      patient_fee_drawer_text:
        'Joint Academy har avtal med flera regioner och beroende på vart du är folkbokförd samt vilken terapeut som finns tillgänglig för de besvär du söker för kan en patientavgift utgå.<br/><br/>Patientavgiften ingår i högkostnadsskyddet och frikort gäller.',
      supports_exemption_card: 'Frikort gäller, ingår i högkostnadsskyddet.',
    },
    details: {
      title: 'Ange dina uppgifter',
      match: 'Matchar dig med en terapeut...',
    },
    payment: {
      title: 'Välj hur du vill betala',
      credit_card_title: 'Kortbetalning',
      credit_card_text: 'Betala nu med kredit- eller kontokort.',
      on_site_title: 'På kliniken',
      on_site_text: 'Betala eller visa ditt frikort i receptionen.',
      exemption_card_title: 'Frikort',
      exemption_card_text: 'Din bokning täcks av högkostnadsskydd. Ange ditt frikort.',
    },
    confirmation: {
      title: 'Tack för din bokning!',
      text: 'En bekräftelse skickas via e-post och SMS.',
      title_book_a_call: 'Tack!',
      text_book_a_call: 'Du kommer att få ett förslag på tid av din fysioterapeut.',
      step_title: 'Nästa steg',
      step_text: 'Ditt möte sker via Joint Academy-appen.',
      step_1: 'Ladda ner Joint Academy-appen',
      step_2: 'Logga in säkert med BankID',
      step_3: 'Förbered dig inför videosamtalet',
      download_app: 'Ladda ner appen',
      appointment_not_found: 'Ingen bokning hittades',
      sms_instructions: 'Inte fått ett SMS? Ange ditt telefonnummer så skickar vi en ny länk.',
    },
    book_a_call: {
      answer_option_no: 'Nej, jag vill föreslå tid.',
      answer_option_yes: 'Ja, skicka bara en bokning.',
      input_placeholder: 'Skriv här',
      no_option_selected_error: 'Välj ett alternativ för att fortsätta',
      no_time_given_error: 'Vänligen ange när du är tillgänglig',
      question: 'Går det att nå dig när som helst de kommande 2-3 dagarna?',
      subtitle: 'Räkna med att samtalet tar 10-15 min.',
    },
  },
  credit_card_dialog: {
    title: 'Dina kortuppgifter',
    to_pay: 'Att betala <strong>{fee}</strong>',
    terms: 'Genom att klicka på Bekräfta godkänner du de <0>Allmänna villkoren</0> och vår <1>Personuppgiftspolicy</1>',
  },
  exemption_card_dialog: {
    title: 'Frikortsuppgifter',
    update_card: 'Uppdatera ditt frikort',
    how_to_find: 'Hur hittar jag mitt frikortsnummer?',
  },
  appointment_mode: {
    digital: 'Digitalt',
    physical: 'Fysiskt',
    none: '',
  },
  profession: {
    physical_therapist: 'Fysioterapeut',
    occupational_therapist: 'Arbetsterapeut',
    speech_therapist: 'Logoped',
    wellness_therapist: 'Friskvårdsterapeut',
  },
  profession_description: {
    physical_therapist: 'Rehabilitering och träning vid smärta, stelhet, muskelsvaghet, m.m.',
    occupational_therapist: 'Hjälp vid sjukdom i händer/fingrar. Hjälpmedel och anpassning i vardagen.',
    speech_therapist: '',
    wellness_therapist: '',
  },
  SCREENING: {
    QUESTIONS: {
      ACUTE: {
        DURATION:
          'Hur länge har du haft besvär i {painJoint, select, hip {höften} knee {knäet} hand {handen} lower_back {ländryggen} shoulder {axeln} neck {nacken} foot {foten} lower_leg {underbenet} wrist {handleden} elbow {armbågen} other{}}?',
        FREQUENCY_OF_PAIN: 'Hur ofta har du ont?',
        EXPERIENCE_OF_PAIN: 'Hur mycket smärta har du?',
        RADIATING_PAIN: 'Strålar smärtan ut i {legArm, select, leg {benet} arm {armen} other{ }}?',
        RADIATING_PAIN_SCALE:
          'Markera den ruta som motsvarar den utstrålande smärtan i {legArm, select, leg {benet} arm {armen} other{ }}',
        NIGHT_PAIN: 'Har du ont på natten?',
        CHEST_PAIN: 'Har du bröstsmärtor eller svårt att andas?',
        REDUCED_SENSATION: 'Upplever du minskad eller förändrad känsel i armar eller ben?',
        REDUCED_SENSATION_GROIN:
          'Har du tappat känsel i underlivet/svårt att hålla tätt för urin och avföring som började samtidigt som din {painJoint, select, hip {höft} knee {knä} hand {hand} lower_back {ländryggs} shoulder {axel} neck {nack} foot {fot} lower_leg {underbens} wrist {handleds} elbow {armbågs} other{ }}smärta?',
        WEIGHT_LOSS: 'Har du upplevt en oförklarlig viktminskning nyligen?',
        SPECIFIC_JOINT_PAIN: 'Markera rutan som motsvarar den genomsnittliga smärtan den senaste veckan',
        SPECIFIC_JOINT_EXAMINED:
          'Har en {painJoint, select, wrist {arbetsterapeut} hand {arbetsterapeut} elbow {fysioterapeut, arbetsterapeut} other {fysioterapeut}}, läkare eller annan vårdgivare fysiskt undersökt {painJoint, select, hip {artrosen i din höft} knee {artrosen i ditt knä} hand {artrosen i din hand} lower_back {din ländrygg} shoulder {din axel} neck {din nacke} foot {din fot} lower_leg {ditt underben} wrist {din handled} elbow {din armbåge} other{}} inom de senaste 3 månaderna?',
      },
      SEEK_HELP_FOR: {
        TITLE: 'Vad kan vi hjälpa dig med?',
        ELBOW: 'Armbåge',
        FALL_PREVENTION: 'Fallprevention/Balansskola',
        FOOT: 'Fot',
        HAND: 'Hand',
        HIP: 'Höft',
        JAW: 'Käkled',
        KNEE: 'Knä',
        LOWER_BACK: 'Ländrygg',
        NECK: 'Nacke',
        NONE: 'Inga besvär',
        OSTEOPOROSIS: 'Osteoporos/Benskörhet',
        SHOULDER: 'Axel',
        SPINE: 'Ryggrad',
        UPPER_BACK: 'Bröstrygg',
        HIP_JOINT: 'Höft',
        KNEE_JOINT: 'Knä',
        WRIST: 'Handled',
        LOWER_LEG: 'Underben',
        OTHER: 'Annat',
        JOINT_NOT_SUPPORTED:
          'Vi behandlar för närvarande inte andra leder, men arbetar för att kunna erbjuda fler behandlingar inom kort. <br><br> Vänligen kontakta din vårdcentral om du behöver hjälp med din ledsmärta.\n\nVill du få mer information om när du kan behandla andra leder med Joint Academy kan du kontakta oss via vårt <0>hjälpcenter</0>.',
      },
      ANSWERS: {
        LESS_MONTH: 'Mindre än 1 månad',
        '1_3_MONTH': '1-3 månader',
        '3_6_MONTH': '3-6 månader',
        MORE_MONTH: 'Mer än 6 månader',
        ACHING: 'Molande',
        AETNA: 'Aetna',
        AFTER_ACTIVITY: 'Efter aktivitet',
        ALWAYS: 'Alltid',
        ARMS_ABOVE_SHOULDER_HEIGHT: 'När jag arbetar med armarna över axelhöjd',
        ARMS_BELOW_SHOULDER_HEIGHT: 'När jag arbetar med armarna nedan axelhöjd',
        AT_NIGHT: 'Jag vaknar på natten av smärta',
        BLUE_CROSS: 'Blue Cross Blue Shield av Rhode Island',
        BLUE_SHIELD_CALIFORNIA: 'Blå sköld Kalifornien',
        BURNING: 'Brännande eller stickande',
        CONCENTRATION_DIFFICULTIES: 'Koncentrationssvårigheter',
        DAILY: 'Varje dag',
        DIZZINESS: 'Yrsel',
        DONT_KNOW: 'Jag vet inte',
        DURING_ACTIVITY: 'Under aktivitet',
        FEMALE: 'Kvinna',
        HAND: 'Ledsmärta i hand',
        HAND_BENHIND_BACK: 'När jag tar handen bakom ryggen',
        HAND_JOINT: {
          CMC: 'Tumbas',
          DIP: 'Ytterled',
          INDEX_FINGER: 'Pekfinger',
          IP: 'Ytterled',
          LITTLE_FINGER: 'Lillfinger',
          MCP: 'Knogled',
          MIDDLE_FINGER: 'Långfinger',
          PIP: 'Mellanled',
          RING_FINGER: 'Ringfinger',
          THUMB: 'Tumme',
          THUMB_MCP: 'Tummens knogled',
        },
        HEADACHE: 'Huvudvärk ',
        HIP: 'Ledsmärta i höft',
        HOURLY: 'Varje timme',
        INCONTINENCE: 'Känslan av att behöva skynda iväg till toaletten',
        HUMANA: 'Humana',
        KNEE: 'Ledsmärta i knä',
        LEFT_LOWER_LEG: 'Vänster underben',
        LEFT_ELBOW: 'Vänster armbåge',
        LEFT_FOOT: 'Vänster fot',
        LEFT_WRIST: 'Vänster handled',
        LEFT_HAND: 'Vänster hand',
        LEFT_HIP: 'Vänster höft',
        LEFT_KNEE: 'Vänster knä',
        LEFT_SHOULDER: 'Vänster axel',
        LIFTING: 'Vid lyft',
        LIGHTHEADEDNESS: 'Yrsel',
        LOW_BACK: 'Ländryggsbesvär',
        LYING: 'Liggande',
        MALE: 'Man',
        MEDICARE: 'Medicare',
        MONTHLY: 'Varje månad',
        MOVING: 'När jag rör på huvudet',
        NECK: 'Nackbesvär',
        NO_HEALTH_PLAN: 'Jag har ingen hälsoplan',
        NO_PAIN: 'Nej, inga',
        NONE_PAIN: 'Jag har inte ont',
        OTHER: 'Annat',
        OTHER_JOINT: 'Annan ledsmärta',
        OUT_IN_THE_ARM: 'Ut i armen',
        PREVIOUS: 'Tidigare vecka {courseweek}',
        REST: 'I vila (t.ex. liggandes i sängen)',
        RIGHT_LOWER_LEG: 'Höger underben',
        RIGHT_ELBOW: 'Höger armbåge',
        RIGHT_FOOT: 'Höger fot',
        RIGHT_WRIST: 'Höger handled',
        RIGHT_HAND: 'Höger hand',
        RIGHT_HIP: 'Höger höft',
        RIGHT_KNEE: 'Höger knä',
        RIGHT_SHOULDER: 'Höger axel',
        SCRATCH_NECK: 'När jag kliar mig i nacken',
        SHARP: 'Huggande',
        SHOULDER: 'Axelbesvär',
        SITTING: 'Sittande',
        SITTING_STANDING: 'När jag sitter eller står',
        SLEEP_DISORDER: 'Sömnstörningar',
        SLEEPINESS: 'Trötthet',
        STANDING: 'Stående',
        STILL: 'När jag är stilla',
        THROBBING: 'Dunkande/pulserande',
        TO_HAND: 'Ända ut i handen/fingrarna',
        TO_SHOULDER: 'Till axeln',
        UNITED_HEALTHCARE: 'United HealthCare',
        UNSTEADINESS: 'Ostadighet',
        WALKING: 'Gåendes',
        WEEKLY: 'Varje vecka',
        WHILE_LYING: 'När jag ligger ner',
        WHILE_WALKING: 'När jag går',
      },
      PAIN_TEXT: {
        AWFUL: 'Fruktansvärd',
        EXCELLENT: 'Utmärkt',
        EXTREME: 'Extrem',
        GREAT_CONDITION: 'Bästa tänkbara',
        MAX_DISABILITY: 'Maximalt funktionshinder',
        MAX_RADIATING: 'Maximal smärta',
        NO_DIFFICULTY: 'Ingen svårighet',
        NO_PAIN: 'Ingen smärta',
        NO_PROBLEMS: 'Inga problem',
        NO_RADIATING: 'Ingen smärta',
        NO_SYMPTOMS: 'Inga symtom',
        NONE: 'Ingen',
        REQUIRES_HELP: 'Så svårt att jag behövde hjälp',
        UNBEARABLE: 'Outhärdlig',
        VERY_GOOD: 'Mycket bra',
        VERY_POOR: 'Mycket dåligt',
        WORST_CONDITION: 'Värsta tänkbara',
        WORST_POSSIBLE: 'Värsta möjliga',
      },
      BACK_NOT_CHRONIC:
        'Vår behandling är utformad för dig som har haft ont i ländryggen i minst 3 veckor. Om din smärta kvarstår är du välkommen tillbaka.',
      BACK_NOT_CHRONIC_QUESTIONS_DESCRIPTION: 'Vår behandling är inte utformad för dig med akuta besvär.',
      BACK_NOT_CHRONIC_TITLE: 'Vi kan tyvärr inte erbjuda dig behandling',
      CHEST_PAIN: 'Får du bröstsmärta eller känner dig andfådd i samband med att din axel gör ont?',
      FALL_PREVENTION: {
        FALL_ASSISTIVE_DEVICE: 'Använder du något gånghjälpmedel såsom en käpp eller rullator?',
        FALL_CONCERN: 'Är du orolig att du ska ramla?',
        FALL_FEEL: 'Känner du dig ostadig när du står eller går?',
        FALL_INJURY: 'Skadade du dig när du föll?',
        FALL_MEDICINE: 'Tar du medicin som ibland kan få dig att känna dig yr, svimfärdig eller trött?',
        FALL_PAIN_CAUSED_BY_ACCIDENT:
          'Har du smärta eller besvär som kan härledas till en olycka eller ett fall under de senaste 3 månaderna?',
        FALL_RATE: 'Hur många gånger har du fallit under de senaste 12 månaderna?',
        FALL_SYMPTOMS: 'Hade du några av dessa symtom före ditt fall? Välj alla alternativ som stämmer för dig.',
        FALL_TIME_ASSISTIVE_DEVICE: 'Använde du ditt gånghjälpmedel när du föll?',
        FALL_TIMES: 'gånger',
        FALL_UNINTENTIONAL: 'Har du fallit (oavsiktligt hamnat på marken) under de senaste 12 månaderna?',
      },
      MOST_PAINFUL_HAND: 'Vilken hand besvärar dig mest?',
      MOST_PAINFUL_HIP: 'Vilken höft besvärar dig mest?',
      MOST_PAINFUL_JOINT:
        '{joint, select, hip {Vilken höft} knee {Vilket knä} hand {Vilken hand} shoulder {Vilken axel} lower_leg {Vilket underben} foot {Vilken fot} elbow {Vilken armbåge} wrist {Vilken handled} other{}} besvärar dig mest?',
      MOST_PAINFUL_JOINT_BOTH:
        'Skulle du ha lika mycket besvär från båda, ange en av dem. Under behandlingen kommer vi regelbundet att följa upp smärtnivån i denna led  .',
      MOST_PAINFUL_JOINT_BOTH_HAND:
        'Du får alltid behandling för båda händerna. Under behandlingen följer vi dock upp smärtan varje vecka i endast den ena, så välj en hand för uppföljning, även om du har lika mycket besvär i båda.',
      MOST_PAINFUL_KNEE: 'Vilket knä besvärar dig mest?',
      MOST_PAINFUL_SHOULDER: 'Vilken axel besvärar dig mest?',
      HAND_SPECIFIC_FINGER_JOINT: 'Markera de leder du har besvär från i nuläget',
      SUBTITLE_SEVERAL_OPTIONS: '(Flera alternativ möjliga)',
      ACCIDENT_EXAMINED: 'Ja, och jag har blivit undersökt',
      ACCIDENT_NOT_EXAMINED: 'Ja, men jag har inte sökt vård',
      PAIN_CAUSED_BY_ACCIDENT: 'Kan du härleda dina smärtor till en olycka på senare tid (senaste 3 månaderna)?',
      RED_FLAG:
        'Vi rekommenderar dig att söka fysisk vård innan du startar. Om din undersökande läkare sedan anser att du kan behandlas med fysioterapi, är du välkommen tillbaka.',
      RED_FLAG_QUESTIONS_DESCRIPTION:
        'För att säkerställa att ditt tillstånd inte kräver fysisk undersökning av en läkare ber vi dig svara på några frågor.',
      RED_FLAG_TITLE: 'Vänligen gå till din läkare',
      HAVE_HAD_CANCER: 'Har du eller har du haft någon form av cancer?',
      FREQUENCY_OF_PAIN:
        'Hur ofta har du besvär i {joint, select,\n lower_back {ländryggen}\n shoulder {axeln}\n neck {nacken}\n other{}\n}?',
      HOW_LONG_BACK_PROBLEMS: 'Har du haft ont i ländryggen i 3 veckor eller längre?',
      JOINT_NOT_SUPPORTED:
        'Vi behandlar för närvarande inte andra leder, men arbetar för att kunna erbjuda fler behandlingar inom kort. Vänligen kontakta din vårdcentral om du behöver hjälp med din ledsmärta.\n\nVill du få mer information om när du kan behandla andra leder med Joint Academy kan du kontakta oss via vårt <a href="https://www.jointacademy.com/se/sv/support">hjälpcenter</a>.',
      LOW_BACK_LOSS_SENSATION:
        'Har du tappat känsel i underlivet/svårt att hålla tätt för urin och avföring som började samtidigt med din ryggsmärta?',
      LOW_BACK_RED_FLAG:
        'Vi rekommenderar dig att söka fysisk vård innan du startar. Om din undersökande läkare sedan anser att du kan behandlas med fysioterapi, är du välkommen tillbaka.',
      NECK_RELATED_PAIN: {
        TITLE: 'Har du några andra symtom relaterat till dina nackbesvär?',
      },
      OSTEOPOROSIS: {
        OSTEOPOROSIS_BACK_PAIN: 'Har du nyligen drabbats av plötslig ryggsmärta?',
        OSTEOPOROSIS_CAUSED_BY_ACCIDENT:
          'Har du smärta eller besvär som kan härledas till en olycka eller ett fall under de senaste 3 månaderna?',
        OSTEOPOROSIS_DIAGNOSED: 'Är du diagnostiserad med osteoporos (benskörhet)?',
        OSTEOPOROSIS_EXERCISE:
          'Har du blivit rekommenderad träning av en läkare, fysioterapeut eller sjuksköterska som en del av din behandling för benskörhet eller låg benmassa?',
        OSTEOPOROSIS_FRACTURE: 'Fick du diagnosen i samband med ett benbrott (fraktur)?',
        OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL:
          'Är din osteoporos ålder/klimakterie-relaterad eller orsakad av läkemedel/bakomliggande sjukdom?',
        OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL_AGE: 'Ålder/klimakterie-relaterad',
        OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL_MEDICAL: 'Orsakad av läkemedel/bakomliggande sjukdom',
        OSTEOPOROSIS_MENOPAUSE_OR_MEDICAL_UNKNOWN: 'Vet ej',
        OSTEOPOROSIS_OSTEOPENI_DIAGNOSED: 'Är du diagnostiserad med osteopeni (låg benmassa)?',
      },
      PAIN_IN_WRISTS: 'Har du smärta i handlederna?',
      PAIN_TYPE_TITLE: 'Hur skulle du beskriva din smärta?',
      PAIN_UNDER_PREASSURE_OR_MOVEMENT:
        'Har du smärta i {painHipKnee, select,\n right_hip {din högra höft}\n left_hip {din vänstra höft}\n right_knee {ditt högra knä}\n left_knee {ditt vänstra knä}\n other{}\n} vid rörelse eller belastning?',
      PRIMARY_INSURANCE_BULLET_1: 'Obegränsad videosupport från en licensierad sjukgymnast',
      PRIMARY_INSURANCE_BULLET_2: 'Enkla dagliga övningar - bara 5 minuter om dagen!',
      PRIMARY_INSURANCE_BULLET_3: 'Interaktiva lektioner för att hjälpa dig förstå ditt tillstånd',
      PRIMARY_INSURANCE_BULLET_4: 'Tips och motivation från din egen stödgrupp',
      PRIMARY_INSURANCE_BULLET_TITLE: 'Dra nytta av:',
      PRIMARY_INSURANCE_CONGRATULATIONS: 'Grattis!',
      PRIMARY_INSURANCE_GREAT_NEWS: 'Goda nyheter!',
      PRIMARY_INSURANCE_IN_NETWORK: 'Din försäkring är i nätverk',
      PRIMARY_INSURANCE_MONTH_THEREAFTER: '89 USD/MÅNAD DÄREFTER',
      PRIMARY_INSURANCE_MOST_POPULAR: 'Mest populära leverantörer',
      PRIMARY_INSURANCE_NO_CREDIT_CARD: 'Inget kreditkort behövs',
      PRIMARY_INSURANCE_NO_INSURANCE: "I don't have health insurance",
      PRIMARY_INSURANCE_NO_SURPRISES: 'Inga överraskningar, avbryt när som helst',
      PRIMARY_INSURANCE_SEE_ALL: 'Se alla försäkringsgivare',
      PRIMARY_INSURANCE_SPECIAL_OFFER: '{name} members are eligible for a limited-time special offer',
      PRIMARY_INSURANCE_START_TRIAL: 'PÅBÖRJA GRATIS FÖRSÖKSPERIOD',
      PRIMARY_INSURANCE_TITLE: 'Vilken är din primära sjukförsäkring?',
      PRIMARY_INSURANCE_X_DAY: '{days} -dagars gratis provperiod',
      PRIMARY_INSURANCE_X_DAY_STARTED: 'Din {days} -dagars kostnadsfria provperiod har börjat',
      RADIATING_DISTANCE: 'Hur långt strålar din nacksmärta?',
      RADIATING_PAIN: 'Markera den ruta som motsvarar hur mycket utstrålande smärta du har i benet',
      RADIATING_PAIN_NECK:
        'Markera den ruta som motsvarar hur mycket utstrålande smärta du har från nacken ut mot armen och/eller handen?',
      RADIATING_PAIN_SHOULDER: 'Markera den ruta som motsvarar hur mycket utstrålande smärta du har i armen',
      REDUCED_SENSATION: 'Har du problem med nedsatt känsel i armar, händer eller fingrar?',
      REDUCED_STRENGTH: 'Har du problem med nedsatt styrka i armar, händer eller fingrar?',
      SOUGHT_CARE_FOR_INJURED_SPECIFIC_JOINT:
        'Har du vid något tillfälle genom olyckshändelse skadat {painHipKnee, select,\n right_hip {din högra höft}\n left_hip {din vänstra höft}\n right_knee {ditt högra knä}\n left_knee {ditt vänstra knä}\n other {}\n} så allvarligt att du uppsökt läkare?',
      SPECIFIC_HAND_JOINT: {},
      SPECIFIC_HAND_JOINT_TITLE: 'Markera samtliga leder i händerna du har besvär från',
      SPECIFIC_JOINT_EXAMINED:
        'Har en fysioterapeut, läkare eller annan vårdgivare fysiskt undersökt {painJoint, select,\n right_hip {artrosen i din högra höft}\n left_hip {artrosen i din vänstra höft}\n right_knee {artrosen i ditt högra knä}\n left_knee {artrosen i ditt vänstra knä}\n right_hand {artrosen i din högra hand}\n left_hand {artrosen i din vänstra hand}\n other {}\n} under de senaste 3 månaderna?',
      SPECIFIC_JOINT_EXAMINED_IN:
        'Har en {painJoint, select, right_hand {arbetsterapeut} left_hand {arbetsterapeut} other {fysioterapeut}}, läkare eller annan vårdgivare fysiskt undersökt {painJoint, select,\n lower_back {ländryggen}\n right_hand {artrosen i din högra hand}\n left_hand {artrosen i din vänstra hand}\n right_shoulder {din högra axel}\n left_shoulder {din vänstra axel}\n neck {nacken}\n other {}\n} under de senaste 3 månaderna?',
      SPECIFIC_JOINT_OA_DIAGNOSED:
        'Har du fått diagnosen artros i {painJoint, select,\n right_hip {din högra höft}\n left_hip {din vänstra höft}\n right_knee {ditt högra knä}\n left_knee {ditt vänstra knä}\n right_hand {din högra hand}\n left_hand {din vänstra hand}\n other {}\n}?',
      SPECIFIC_JOINT_PAIN_MAX: 'Outhärdlig',
      SPECIFIC_JOINT_PAIN_MIN: 'Ingen smärta',
      STIFFNESS_DURATION: 'Hur länge varar stelheten?',
      STIFFNESS_LESS_DURATION_HIP: 'Mindre än en timme',
      STIFFNESS_LESS_DURATION_KNEE: 'Mindre än en halvtimme',
      STIFFNESS_MORE_DURATION_HIP: 'Mer än en timme',
      STIFFNESS_MORE_DURATION_KNEE: 'Mer än en halvtimme',
      STIFFNESS_PRESENT: 'Upplever du stelhet på morgonen?',
      SWOLLEN_KNUCKLE_JOINTS: 'Är dina knogleder (där benen i fingrarna och handen möts) svullna?',
      SWOLLEN_WRISTS: 'Är dina handleder svullna?',
      TITLE: 'Hälsoformulär',
      WHEN_LOW_BACK_PAIN_OCCURS: {},
      WHEN_PAIN_OCCURS:
        'Beskriv i vilka situationer du har {joint, select,\n lower_back {ländryggsbesvär}\n shoulder {axelbesvär}\n neck {nackbesvär}\n other{}\n}?',
      WORK_STANDING_WALKING_ROTATING:
        'Har du, eller har du haft, ett arbete som medför stående, gående eller roterande uppgifter?',
      HEALTH_ALLJOINTS_PAINSCORE:
        'Markera den ruta som motsvarar den genomsnittliga smärtan från {painJoint, select,\n right_hip {din högra höft}\n left_hip {din vänstra höft}\n right_knee {ditt högra knä}\n left_knee {ditt vänstra knä}\n right_hand {din högra hand}\n left_hand {din vänstra hand}\n lower_back {ländryggen}\n right_shoulder {din högra axel}\n left_shoulder {din vänstra axel}\n neck {nacken}\n right_elbow {din högra armbåge}\n left_elbow {din vänstra armbåge}\n right_foot {din högra fot}\n left_foot {din vänstra fot}\n right_wrist {din högra handled}\n left_wrist {din vänstra handled}\n right_lower_leg {ditt högra underben}\n left_lower_leg {ditt vänstra underben}\n other {}\n} den senaste veckan:',
      GENDER: {
        TITLE: 'Ange ditt kön',
        MALE: 'Man',
        FEMALE: 'Kvinna',
      },
      WEIGHT: {
        TITLE: 'Ange din vikt',
        LB: 'lb',
        WEIGHT_RANGE_LB: 'Has to be {min}-{max} lbs',
        WEIGHT_RANGE_KG: 'Vikten måste vara mellan {min}-{max} kg',
      },
      HEIGHT: {
        TITLE: 'Ange din längd',
        FT: 'ft',
        CM_RANGE: 'Längden måste vara mellan {min} och {max} cm',
        FEET_RANGE: 'Feet has to be between 3 and 8',
        INCHES_RANGE: 'Inches has to be between 0 and 11',
        IN: 'in',
      },
    },
  },
};
