/* eslint-disable import/no-cycle */
import { isSSR } from '@utils/ssr/ssr';
import { clearAll } from '@utils/cookie/clearAll';

export type TDeepLinkAppointment = {
  serviceId?: string;
  healthCareProfessionalId?: string;
  fromTime?: string;
};

export function getDeepLinkAppointment(): TDeepLinkAppointment | undefined {
  const deepLinkAppointment = localStorage.getItem('DEEP_LINK_APPOINTMENT');

  return deepLinkAppointment ? JSON.parse(deepLinkAppointment) : undefined;
}

export function storeDeepLinkAppointment(deepLinkAppointment: TDeepLinkAppointment) {
  localStorage.setItem('DEEP_LINK_APPOINTMENT', JSON.stringify(deepLinkAppointment));
}

export function removeDeepLinkAppointment() {
  localStorage.removeItem('DEEP_LINK_APPOINTMENT');
}

export const initDeepLinkAppointmentBooking = () => {
  if (isSSR()) {
    return;
  }

  const url = new URL(window.location.href);
  const serviceId = url.searchParams.get('a_s');
  const fromTime = url.searchParams.get('a_t');
  const healthCareProfessionalId = url.searchParams.get('a_hcprof');

  if (!serviceId || !fromTime || !healthCareProfessionalId) {
    return;
  }

  clearAll();
  storeDeepLinkAppointment({
    serviceId,
    fromTime,
    healthCareProfessionalId,
  });

  url.searchParams.delete('a_s');
  url.searchParams.delete('a_t');
  url.searchParams.delete('a_hcprof');

  window.history.replaceState(window.history.state, '', url.href);
};
