import type { AxiosResponse } from 'axios';
import axios from 'axios';

import { AuthClient } from '@/src/externalApi';
import { signOutUser } from '@utils/auth';
import type { IAuthTokens } from '@utils/cookie';
import { getAuthTokens, setAuthCookies } from '@utils/cookie';

// try to refresh token a little while before it expires, just to be safe (seconds)
const EXPIRE_FUDGE = 60;

const isTokenExpired = (tokens: IAuthTokens): boolean => {
  const { idToken } = tokens;

  // simple decoding of JWT
  const parts = idToken.split('.');
  const json = parts[1];
  const claims = JSON.parse(atob(json));

  const expiresAt = claims.exp || -1;
  const expiresIn = expiresAt - Date.now() / 1000;

  return expiresIn <= EXPIRE_FUDGE;
};

interface RefreshTokenResponse {
  result: {
    id_token: string;
  };
}

// Returns true if successful, false if not and the user should be logged out.
// Throws error if there's a (temporary) network error. The user should not be logged out in those cases.
const refreshToken = async (): Promise<boolean> => {
  const authTokens = getAuthTokens();

  if (!authTokens) return false;

  let response: AxiosResponse<RefreshTokenResponse>;
  try {
    response = await AuthClient.post('/refresh_token', {
      id_token: authTokens.idToken,
      refresh_token: authTokens.refreshToken,
    });
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      // session has expired or is invalid - log out.
      return false;
    }
    throw error;
  }

  setAuthCookies({
    idToken: response.data.result.id_token,
    refreshToken: authTokens.refreshToken,
  });

  return true;
};

const actualRefreshTokenOrSignOut = async (): Promise<boolean> => {
  const refreshed = await refreshToken();
  if (!refreshed) {
    await signOutUser('session_expired');
    return false;
  }
  return true;
};

let running: Promise<boolean> | null = null;

// only 1 refresh token should run at a time, if one is already running return that promise.
const refreshTokenOrSignOut = (): Promise<boolean> => {
  if (running) return running;
  running = actualRefreshTokenOrSignOut().finally(() => {
    running = null;
  });
  return running;
};

const refreshTokenIfNeeded = async (): Promise<void> => {
  const authTokens = getAuthTokens();
  if (!authTokens) return; // no valid session, do nothing
  if (isTokenExpired(authTokens)) {
    const refreshed = await refreshTokenOrSignOut();
    if (!refreshed) {
      throw new Error('Failed to refresh token, user was logged out.');
    }
  }
};

export { refreshTokenOrSignOut, refreshTokenIfNeeded, isTokenExpired };
