import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

import { ApiClient } from './ApiClient';
import { ApiClientContext } from './ApiClientContext';
import transport from './AxiosClient';

export const ApiClientProvider = ({ children }: { children: ReactNode }) => {
  const client = useMemo(() => new ApiClient(transport), []);

  return <ApiClientContext.Provider value={client}>{children}</ApiClientContext.Provider>;
};

export default ApiClientProvider;
