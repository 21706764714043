// https://www.figma.com/file/EarM8XBErewqQRcANnKkKr/Product-Colors?node-id=0%3A1
// primary and secondary swapped places since compared to native app.

const Hues = {
  base: {
    black: '#000000',
    white: '#FFFFFF',
    whiteTemp: '#FFFDFC',
    neutral: '#FAFAFA',
    overlay: '#730E407E',
    tipColor: '#778A9D',
    secondary: '#7F94A9',
    senaryText: '#E7BD2F',
  },
  // Blue
  primary: {
    _5: '#F9FBFE',
    _10: '#EDF4FC',
    _15: '#E4EFFB',
    _20: '#DCEAFA',
    _30: '#CADFF8',
    _40: '#B9D5F6',
    _50: '#A7CAF3',
    _60: '#96C0F1',
    _70: '#84B5EE',
    _80: '#73ABED',
    _90: '#61A0EA',
    _100: '#5096E8',
    _110: '#377DCF', // needed a darker blue for focus color on buttons etc.
  },
  // Dark Blue
  secondary: {
    _5: '#F5F8FC',
    _10: '#E8EFF7',
    _20: '#E1E8EF',
    _30: '#D4DBE2',
    _40: '#B0BBC8',
    _50: '#8D9BAD',
    _60: '#6F7F93',
    _70: '#536479',
    _80: '#3C4C60',
    _90: '#273546',
    _100: '#17222F',
  },
  // Orange
  tertiary: {
    _10: '#FCF3ED',
    _20: '#FAE7DC',
    _30: '#F8DBCA',
    _40: '#F5CFB9',
    _50: '#F3C4A7',
    _60: '#F1B896',
    _70: '#EEAC84',
    _80: '#ECA073',
    _90: '#E78A51',
    _100: '#E88950',
  },
  // Teal
  quaternary: {
    _10: '#EFF9F7',
    _20: '#DFF2F0',
    _30: '#CFECE8',
    _40: '#BFE6E1',
    _50: '#AFE0DA',
    _60: '#9FDAD2',
    _70: '#90D4CB',
    _80: '#7FCEC3',
    _90: '#70C8BC',
    _100: '#60C2B5',
  },
  // Purple
  quinary: {
    _10: '#F1EFF9',
    _20: '#E3DFF2',
    _30: '#D5CFEC',
    _40: '#C7BFE6',
    _50: '#B9AFE0',
    _60: '#AB9FDA',
    _70: '#9D90D4',
    _80: '#8F7FCE',
    _90: '#8270C8',
    _100: '#7460C2',
  },
  // Yellow
  senary: {
    _10: '#FCF9ED',
    _20: '#FAF3DC',
    _30: '#F8EDCA',
    _40: '#F5E7B9',
    _50: '#F3E2A7',
    _60: '#F1DC96',
    _70: '#EED684',
    _80: '#ECD073',
    _90: '#EACA61',
    _100: '#E8C550',
  },
  // Green
  septenary: {
    _10: '#EFF9F3',
    _20: '#DFF2E6',
    _30: '#CFECDA',
    _40: '#BFE6CE',
    _50: '#AFE0C2',
    _60: '#9FDAB6',
    _70: '#90D4AA',
    _80: '#7FCE9E',
    _90: '#70C892',
    _100: '#60C286',
  },
  // Red
  octonary: {
    _10: '#FCEFED',
    _20: '#FAE0DC',
    _30: '#F8D1CA',
    _40: '#F5C1B9',
    _50: '#F3B2A7',
    _60: '#F1A296',
    _70: '#EE9384',
    _80: '#EC8373',
    _90: '#EA7461',
    _100: '#E86550',
  },
};

export const DefaultHeaderHue = Hues.secondary._90;
export const DefaultBodyHue = Hues.secondary._90;
export const DefaultIconHue = Hues.secondary._50;

export default Hues;
