import type { AxiosInstance } from 'axios';

import type { GetAuthenticatedUserResponse } from './types/GetAuthenticatedUser';
import { baseURLv2 } from '@/src/externalApi/AxiosClient';

export class ApiClient {
  private transport: AxiosInstance;

  constructor(transport: AxiosInstance) {
    this.transport = transport;
  }

  getAuthenticatedUser(): Promise<GetAuthenticatedUserResponse> {
    return this.transport.get<GetAuthenticatedUserResponse>('/me', { baseURL: baseURLv2 })
      .then(({ data }) => data);
  }
}

export default ApiClient;
