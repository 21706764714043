import Cookies from 'js-cookie';

interface AuthTokens {
  idToken: string;
  refreshToken: string;
}

// expire number in days
const EXPIRE = 14;

// Private method
const setCookies = (entries: { [key: string]: string }) => {
  // indicating if the cookie transmission requires a secure protocol (https)
  const secure = process.env.REACT_APP_SECURE_COOKIES === 'true';

  Object.entries(entries).forEach(entry => {
    const [key, value] = entry;

    Cookies.set(key, value, { expires: EXPIRE, secure });
  });
};

const setAuthCookies = (tokens: AuthTokens): void => {
  setCookies({
    rja_auth_type: 'new',
    rja_auth_id_token: tokens.idToken,
    rja_auth_refresh_token: tokens.refreshToken,
  });
};

export { setAuthCookies };
