import Hues, { DefaultHeaderHue, DefaultBodyHue } from './Hues';

function createDefinition(
  color: string,
  fontSize: number,
  lineHeight: number,
  fontWeight: number,
  letterSpacing: number,
) {
  const relativeLineHeight = lineHeight / fontSize;
  return {
    color,
    fontSize,
    lineHeight: relativeLineHeight,
    fontWeight,
    letterSpacing,
  };
}

export const Definitions = {
  h1: createDefinition(DefaultHeaderHue, 56, 58, 700, 0.5),
  h2: createDefinition(DefaultHeaderHue, 28, 38, 700, -0.75),
  h3: createDefinition(DefaultHeaderHue, 22, 32, 700, 0.15),
  h4: createDefinition(DefaultHeaderHue, 18, 24, 700, 0.15),
  h5: createDefinition(DefaultHeaderHue, 18, 28, 700, 0.3),
  overline1: createDefinition(DefaultHeaderHue, 14, 20, 900, 1.25),
  overline2: createDefinition(DefaultHeaderHue, 12, 16, 900, 0.5),
  caption1: createDefinition(DefaultHeaderHue, 12, 16, 500, 0.75),
  caption2: createDefinition(DefaultHeaderHue, 12, 16, 700, 0.75),
  subtitle1: createDefinition(Hues.secondary._50, 14, 20, 500, 0),
  subtitle2: createDefinition(Hues.secondary._80, 14, 20, 500, 0),
  subtitle3: createDefinition(Hues.secondary._50, 12, 20, 500, 0),
  body1: createDefinition(DefaultBodyHue, 16, 24, 500, 0),
  body2: createDefinition(DefaultBodyHue, 16, 20, 500, 0),
  body3: createDefinition(DefaultBodyHue, 16, 20, 700, 0),
  body4: createDefinition(DefaultBodyHue, 14, 20, 500, 0),
};
