import i18n from 'i18next';
import ICU from 'i18next-icu';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

import svTranslations from '@/src/locales/sv/translations';

i18n
  // @ts-ignore
  .use(Backend)
  .use(initReactI18next)
  .use(
    new ICU({
      parseErrorHandler: (_: Error, key: string) => key,
    }),
  )
  .init({
    resources: {
      sv: {
        translation: svTranslations,
      },
    },
    fallbackLng: 'sv',
    lng: 'sv',
    debug: false,
    returnNull: false,
    parseMissingKeyHandler: (key: string) => key,
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'a'],
      useSuspense: true,
    },
  });

export default i18n;
