import React from 'react';
import { ThemeProvider, StyledEngineProvider, Theme } from '@mui/material';
import { setDefaultOptions } from 'date-fns';
import svLocale from 'date-fns/locale/sv';
import ApiClientProvider from '@/src/externalApi/ApiClientProvider';
import { QueryClientProvider } from 'react-query';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { I18nextProvider } from 'react-i18next';
import { GatsbySSR } from 'gatsby';
import RootLayout from '@components/RootLayout';
import theme from '@/src/theme';
import cacheClient from '@/src/cacheClient';
import i18n from './src/components/common/i18n';
import ProfileContextProvider from './src/contexts/profileContext/ProfileContextProvider';
import AnalyticsContextProvider from './src/contexts/analyticsContext/AnalyticsContextProvider';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const headComponents = [
  <link
    key="global-style"
    type="text/css"
    href="https://assets.jointacademy.com/framework/v1.3.0/style.css"
    rel="stylesheet"
  />,
  <link
    key="sms-css"
    rel="stylesheet"
    href="https://assets.jointacademy.com/framework/sms/v4.0.0/sms.css"
    type="text/css"
    media="all"
  />,
  <script
    key="intlTelInput-js"
    src="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/intlTelInput.min.js"
    type="text/javascript"
    defer
  />,
  <script
    key="sms-js"
    src="https://assets.jointacademy.com/framework/sms/v4.0.0/sms.js"
    type="text/javascript"
    defer
  />,
];

export const onRenderBody: GatsbySSR['onRenderBody'] = ({ setHeadComponents }) => {
  setHeadComponents(headComponents);
};

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <RootLayout>{element}</RootLayout>;
};

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => {
  setDefaultOptions({ locale: svLocale, weekStartsOn: 1 });

  return (
    <AnalyticsContextProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={svLocale}>
        <ApiClientProvider>
          <QueryClientProvider client={cacheClient}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <I18nextProvider i18n={i18n}>
                  <ProfileContextProvider>
                    {element}
                  </ProfileContextProvider>
                </I18nextProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </QueryClientProvider>
        </ApiClientProvider>
      </LocalizationProvider>
    </AnalyticsContextProvider>
  );
};
