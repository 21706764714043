import type { Axios, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';

import { signOutUser } from '@utils/auth';
import { refreshTokenIfNeeded } from '@utils/auth/queries';
import { getRestHeaders } from '@utils/cookie';

// Added console logs to display network access in unit tests on CI
const ciLog = (...args: unknown[]) => {
  if (process.env.CI) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

const authHeadersInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  ciLog('request success', `${config.method} ${config.baseURL} ${config.url}`);

  await refreshTokenIfNeeded();

  return {
    ...config,
    headers: {
      ...(config.headers || {}),
      ...(getRestHeaders() || {}),
    },
  };
};

const rejectedRequestHandler = (response: AxiosError) => {
  ciLog('request error', `${response.config.method} ${response.config.baseURL} ${response.config.url}`);

  return Promise.reject(response);
};

const fulfilledResponseHandler = (response: AxiosResponse) => {
  ciLog('response success', `${response.config.method} ${response.config.baseURL} ${response.config.url}`);

  return response;
};

const notAuthorizedErrorInterceptor = async (error: unknown): Promise<AxiosResponse> => {
  if (axios.isAxiosError(error)) {
    ciLog('response error: ', `${error.response}`);
  } else {
    ciLog('response error: ', error);
  }

  if (axios.isAxiosError(error) && error.response?.status === 401) {
    await signOutUser('session_expired');
  }
  return Promise.reject(error);
};

const configureAuthInterceptors = (client: Axios): void => {
  client.interceptors.request.use(authHeadersInterceptor, rejectedRequestHandler);
  client.interceptors.response.use(fulfilledResponseHandler, notAuthorizedErrorInterceptor);
};

export { authHeadersInterceptor, notAuthorizedErrorInterceptor, configureAuthInterceptors };
