import axios from 'axios';

import { getAppDataRegion } from '@utils/region';

const client = axios.create({
  baseURL: `${process.env.GATSBY_REACT_APP_AUTH_URL}`,
  headers: {
    'ja-app-name': 'patient-web-booking',
    'ja-app-build-number': process.env.GATSBY_REACT_APP_VERSION as string,
    'ja-app-market': getAppDataRegion() as string,
  },
});

export default client;
