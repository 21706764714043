import { QueryClient } from 'react-query';

const cacheClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 20000,
    },
  },
});

export default cacheClient;
