import type { AxiosResponse } from 'axios';

import { AxiosInstance } from '@/src/externalApi';
import { getRestHeaders } from '@utils/cookie';
import { Patient } from '@/src/externalApi/models/Patient';
import { baseURLv2 } from '@/src/externalApi/AxiosClient';

const getUserData = async (): Promise<AxiosResponse<{ data: Patient }>> => {
  // small speed improvement that avoids unnecessary requests
  if (!getRestHeaders()) throw new Error('Not logged in.');
  return AxiosInstance.get('/me', { baseURL: baseURLv2 });
};

export default getUserData;
