import { TBookingTag, TProfession } from '@/src/types/Booking';
import {
  TAppointmentMode,
  TServiceCategory,
  TPurpose,
  TService,
  TCareType,
} from '@/src/externalApi/schedule/models/Service';
import { TAppointmentSlot } from '@/src/externalApi/schedule/models/Appointment';
import { THealthCareProfessional } from '@/src/externalApi/schedule/models/HealthCareProfessional';

type TBookingValue =
| TCareType
| TAppointmentSlot
| THealthCareProfessional
| TService
| TServiceCategory
| TAppointmentMode
| TProfession
| TPurpose
| undefined;

type TBookingStorage = {
  careType?: TCareType,
  appointmentMode?: TAppointmentMode;
  healthCareProfessional?: THealthCareProfessional;
  serviceCategory?: TServiceCategory;
  service?: TService;
  appointmentSlot?: TAppointmentSlot;
  profession?: TProfession;
  purpose?: TPurpose;
};

const bookingTagOrder: TBookingTag[] = [
  'CARE_TYPE',
  'APPOINTMENT_MODE',
  'PROFESSION',
  'SERVICE_CATEGORY',
  'PURPOSE',
  'SERVICE',
  'HEALTH_CARE_PROFESSIONAL',
  'APPOINTMENT_SLOT',
];

let bookingAnswers: Map<string, TBookingValue> = new Map();
try {
  const storedAnswers = sessionStorage.getItem('BOOKING_ANSWERS');
  if (storedAnswers) {
    bookingAnswers = new Map(JSON.parse(storedAnswers));
  }
} catch {
  console.warn('failed parsing answers');
}

function clearUpcomingBookingAnswers(tag: TBookingTag) {
  const tagIndex = bookingTagOrder.findIndex(orderedTag => orderedTag === tag);

  if (tagIndex > -1) {
    bookingTagOrder.forEach((orderedTag, index) => {
      if (index > tagIndex) {
        bookingAnswers.delete(orderedTag);
      }
    });

    sessionStorage.setItem('BOOKING_ANSWERS', JSON.stringify(Array.from(bookingAnswers.entries())));
  }
}

export function getBookingAnswer(tag: TBookingTag): TBookingValue | undefined {
  return bookingAnswers.get(tag);
}

export const getBookingAnswers = (): TBookingStorage => ({
  careType: getBookingAnswer('CARE_TYPE') as TCareType,
  appointmentMode: getBookingAnswer('APPOINTMENT_MODE') as TAppointmentMode,
  profession: getBookingAnswer('PROFESSION') as TProfession,
  healthCareProfessional: getBookingAnswer('HEALTH_CARE_PROFESSIONAL') as THealthCareProfessional,
  serviceCategory: getBookingAnswer('SERVICE_CATEGORY') as TServiceCategory,
  service: getBookingAnswer('SERVICE') as TService,
  appointmentSlot: getBookingAnswer('APPOINTMENT_SLOT') as TAppointmentSlot,
  purpose: getBookingAnswer('PURPOSE') as TPurpose,
});

export function storeBookingAnswer(
  tag: TBookingTag,
  optionId: TBookingValue,
  clearUpcoming = false,
) {
  bookingAnswers.set(tag, optionId);
  sessionStorage.setItem('BOOKING_ANSWERS', JSON.stringify(Array.from(bookingAnswers.entries())));

  if (clearUpcoming) {
    clearUpcomingBookingAnswers(tag);
  }
}

export function clearBookingAnswers() {
  bookingAnswers.clear();
  sessionStorage.removeItem('BOOKING_ANSWERS');
}
