import React, { createContext, ReactNode, useMemo } from 'react';
import { deviceDetect } from 'react-device-detect';
import { isSSR } from '@utils/ssr/ssr';
import { getUtmParams } from '@/src/storage/utmStorage';

export type SetAnonymousId = (id: string) => void;
export type Track = (name: string, properties?: any) => void;
export type Identify = (userId?: number | null, props?: any) => void;
export type Page = (path: string, properties?: any) => void;

export interface AnalyticsState {
  setAnonymousId: SetAnonymousId
  identify: Identify
  track: Track
  page: Page
  reset: () => void
}

const analyticsState: AnalyticsState = {
  setAnonymousId: () => {
    // default values
  },
  identify: () => {
    // default values
  },
  track: () => {
    // default values
  },
  page: () => {
    // default values
  },
  reset: () => {
    // default values
  },
};

export const AnalyticsContext = createContext(analyticsState);

const AnalyticsContextProvider = (props: { children: ReactNode }) => {
  const sendDebugEvent = (o: {
    type: 'Track' | 'Page' | 'Identify'
    payload: Record<string, string>
  }) => {
    if (process.env.GATSBY_APP_PRODUCTION_CONFIG === 'false') {
      setTimeout(
        () => window.dispatchEvent(new CustomEvent('jaAnalyticsEvent', { detail: o })),
        250,
      );
    }
  };

  const { children } = props;
  let deviceInfo = {};
  if (!isSSR()) {
    deviceInfo = deviceDetect(window.navigator.userAgent);
  }

  const analytics: AnalyticsState = useMemo(() => ({
    setAnonymousId: id => {
      window?.analytics?.setAnonymousId(id);
    },

    identify: (userId, properties) => {
      const params = { ...properties, ...getUtmParams() };
      sendDebugEvent({ type: 'Identify', payload: params });
      if (userId) {
        window?.analytics?.identify(userId, params);
      } else {
        window?.analytics?.identify(params);
      }
    },

    track: (name, properties) => {
      const params = {
        ...properties,
        ...getUtmParams(),
        platform: 'clinic_onboarding',
        deviceInfo,
      };
      sendDebugEvent({ type: 'Track', payload: { name, ...params } });
      window?.analytics?.track(name, params);
    },

    reset: () => {
      window?.analytics?.reset();
    },

    page: (name, properties) => {
      const params = {
        ...properties,
        ...getUtmParams(),
        platform: 'clinic_onboarding',
        deviceInfo,
      };
      sendDebugEvent({ type: 'Page', payload: { name, ...params } });
      window?.analytics?.page(name, params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
};

export default AnalyticsContextProvider;
