import cacheClient from '@/src/cacheClient';
import { getRestHeaders } from '@utils/cookie';
import { clearAll } from '@utils/cookie/clearAll';

import { signOut } from './queries';

let signingOut = false;

const signOutUser = async (error?: string): Promise<void> => {
  const authHeaders = getRestHeaders();

  if (authHeaders && !signingOut) {
    try {
      signingOut = true;
      await signOut();
    } finally {
      clearAll();
      cacheClient.clear();
      signingOut = false;

      // TODO make general
      const [, rootPath, clinicPath] = window.location.pathname.split('/');
      const loginPath = `${rootPath ? `/${rootPath}` : ''}${clinicPath ? `/${clinicPath}` : ''}/logga-in`
      if (error) {
        const redirectUrlParam = `?redirectUrl=${encodeURIComponent(window.location.pathname)}`;
        const errorParam = `&error=${encodeURIComponent(error)}`;
        window.location.replace(loginPath + redirectUrlParam + errorParam);
      } else {
        window.location.replace(loginPath);
      }
    }
  }
};

export { signOutUser };
