import Cookies from 'js-cookie';

const removeAuthCookies = (): void => {
  Cookies.remove('rja_auth_type');
  Cookies.remove('rja_auth_headers');
  Cookies.remove('rja_auth_id_token');
  Cookies.remove('rja_auth_refresh_token');
};

export default removeAuthCookies;
