import { getAnswer, getSeekHelpFor } from '@/src/storage/healthQuestionnaireStorage';

export const hasPainCondition = () => getAnswer('ACUTE_FREQUENCY_OF_PAIN') !== 'never';
export const hasRadiatingPainCondition = () => getAnswer('ACUTE_RADIATING_PAIN') === 'yes';
export const abdomenCondition = () => ['lower_back', 'hip'].includes(getSeekHelpFor());
export const bothSidesCondition = (): boolean => (
  ['hip', 'knee', 'shoulder', 'hand', 'wrist', 'elbow', 'foot', 'lower_leg'].includes(getSeekHelpFor())
);
export const acuteCondition = () => {
  const durationAnswer = getAnswer('ACUTE_DURATION_OF_PAIN')?.toString();
  const isAdditionalJoint = ['lower_leg', 'wrist', 'elbow', 'foot'].includes(getSeekHelpFor());

  return durationAnswer === 'less_than_1_month' || durationAnswer === '1_to_3_months' || isAdditionalJoint;
};
