import axios from 'axios';

import { configureAuthInterceptors } from '@/src/utils/auth/axiosInterceptors';
import { getAppDataRegion } from '@utils/region';

export const baseURLv2 = `${process.env.GATSBY_REACT_APP_GO_URL}/v2/`;

const client = axios.create({
  baseURL: `${process.env.GATSBY_REACT_APP_RAILS_URL}/api/v1/`,
  headers: {
    'ja-app-name': 'patient-web-booking',
    'ja-app-build-number': process.env.GATSBY_REACT_APP_VERSION as string,
    'ja-app-market': getAppDataRegion() as string,
  },
});

configureAuthInterceptors(client);

export default client;
