import { clearBookingAnswers } from '@/src/storage/bookingStorage';
import { clearAnswers } from '@/src/storage/healthQuestionnaireStorage';
import { removeAuthCookies } from '@utils/cookie/index';
import { removeFirstVisit } from '@/src/storage/firstVisit';
import { removeDeepLinkAppointment } from '@/src/storage/deepLinkAppointmentStorage';

const clearAllCookies = () => {
  removeAuthCookies();
};

export const clearAllBookingData = (clearHealthQuestionnaire = true) => {
  clearBookingAnswers();

  if (clearHealthQuestionnaire) {
    clearAnswers();
  }
};

export const clearAll = (clearHealthQuestionnaire = true) => {
  clearAllCookies();
  clearAllBookingData(clearHealthQuestionnaire);
  removeFirstVisit();
  removeDeepLinkAppointment();
  window?.analytics?.reset();
};
